import React from "react";
import "./section3.scss";
import image1 from "../../../assets/service1.webp";
import image2 from "../../../assets/service2.webp";
import image3 from "../../../assets/service3.webp";
import image4 from "../../../assets/service4.webp";

const Section3 = () => {
  const info = [
    {
      image: image1,
      heading: "F&A and Tax & Compliance",
      description: [
        "Finance and Accounts",
        "FP&A and MIS",
        "Internal Audit",
        "Tax and Compliance",
      ],
    },
    {
      image: image2,
      heading: "Human Resource",
      description: [
        "HR Outsourcing",
        "HR Analytics",
        "HR Compliance",
        "Contractor Compliance",
        "Manpower Outsourcing",
      ],
    },
    {
      image: image3,
      heading: "Business & Management Insight",
      description: [
        "Business Strategy",
        "Business Research",
        "Change Management",
        "Process Management",
        "CRM Analytics",
        "Sales Force Management",
      ],
    },
    {
      image: image4,
      heading: "Information Technology",
      description: [
        "IT Outsourcing",
        "Digital Strategy",
        "IT Infrastructure Management",
        "Data Migration",
        "Disaster Management",
        "VAPT",
        "ERP Implementation",
        "Cloud Service",
      ],
    },
  ];

  return (
    <div className="section3-container">
      <div className="section3-intro-container">
        <h1>
          our <span>service</span>
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque ratione
          debitis quasi placeat at sequi consequatur veniam atque quo dolorem?
        </p>
      </div>
      <div className="section3-grid-container">
        {info.map((item, index) => (
          <div className="details-container" key={index}>
            <div className="info-card">
              <img src={item.image} alt={item.heading} className="info-image" />
              <h2 className="info-heading">{item.heading}</h2>
              <ul className="info-description">
                {item.description.map((desc, descIndex) => (
                  <li key={descIndex}>{desc}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section3;
