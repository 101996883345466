import React, { useState } from 'react';
import './section11.scss';
import { FaTelegramPlane } from "react-icons/fa";
import { PiHandshakeFill } from "react-icons/pi";
import { FaIndustry } from "react-icons/fa";

const Section11 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const info = [
    {
      icon: <FaTelegramPlane />,
      heading: "Startup Business",
      description:
        "Finapsys provides financial, analytical and technological expertise, along with experience in business strategy that enable entrepreneurs to make better decisions in the startup period, across a wide swath of operations and create long term value.",
    },
    {
      icon: <PiHandshakeFill />,
      heading: "New & Early Stage Business",
      description:
        "Finapsys provides its services to new and early-stage companies to move them through their first and second years of operations.",
    },
    {
      icon: <FaIndustry />,
      heading: "Establish Business",
      description:
        "Finapsys provides it clientele financial and technology services through the life of their initial five-year business plans as they move through their business operations.",
    },
  ];

  return (
    <div className="home2-section11-container">
      <div className="home2-section11-heading-intro">
        <span>our solution</span>
        <h1>
          our <span>customized</span> solution
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Explicabo
          consequatur unde praesentium quasi. Inventore dicta a obcaecati
          doloremque odit amet, accusantium voluptatibus qui cumque quas!
        </p>
      </div>

      <div className="tabs">
        {info.map((item, index) => (
          <div
            key={index}
            className={`tab ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <div className="tab-icon">{item.icon}</div>
            <h2 className="tab-heading">{item.heading}</h2>
          </div>
        ))}
      </div>

      <div className="tab-content">
        <p>{info[activeTab].description}</p>
      </div>
    </div>
  );
}

export default Section11