import React from "react";
import "./home3.scss";
import Section1 from "../../../components/home/home3/Section1";
import Section2 from "../../../components/home/home3/Section2";
import Section4 from "../../../components/home/home3/Section4";
import Section5 from "../../../components/home/home3/Section5";
import Section6 from "../../../components/home/home3/Section6";
import Section7 from "../../../components/home/home3/Section7";
import Section8 from "../../../components/home/home3/Section8";
import Section9 from "../../../components/home/home3/Section9";
import Section10 from "../../../components/home/home3/Section10";
import Section11 from "../../../components/home/home3/Section11";
import Section12 from "../../../components/home/home3/Section12";
import Section13 from "../../../components/home/home3/Section13";

const Home3 = () => {
  return (
    <div className="home-page-container">
      <Section1 />
      <Section2 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13/>
    </div>
  );
};

export default Home3;
