import React, { useState, useEffect } from "react";
import "./section11.scss";
import client1 from "../../../assets/client1.jpg";
import client2 from "../../../assets/client2.jpg";
import client3 from "../../../assets/client3.jpg";
import backgroundImage from "../../../assets/18.jpg";

const Section11 = () => {
  const info = [
    {
      client: client1,
      testimonial:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, inventore laboriosam. Eaque quidem tempora molestias sit a natus animi sint iusto accusantium? Aliquam totam eius magnam a reiciendis natus maxime tempore suscipit ut expedita obcaecati voluptate, hic quas blanditiis, porro cumque dicta, ratione molestias? Tempore quia asperiores fuga laborum rem?",
      name: "Harish Jain",
      designation: "C.E.O. of Retail Chain",
    },
    {
      client: client2,
      testimonial:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, inventore laboriosam. Eaque quidem tempora molestias sit a natus animi sint iusto accusantium? Aliquam totam eius magnam a reiciendis natus maxime tempore suscipit ut expedita obcaecati voluptate, hic quas blanditiis, porro cumque dicta, ratione molestias? Tempore quia asperiores fuga laborum rem?",
      name: "John Doe",
      designation: "Business Analyst",
    },
    {
      client: client3,
      testimonial:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, inventore laboriosam. Eaque quidem tempora molestias sit a natus animi sint iusto accusantium? Aliquam totam eius magnam a reiciendis natus maxime tempore suscipit ut expedita obcaecati voluptate, hic quas blanditiis, porro cumque dicta, ratione molestias? Tempore quia asperiores fuga laborum rem?",
      name: "Alex",
      designation: "Software Engineer",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % info.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + info.length) % info.length);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % info.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [info.length]);

  return (
    <div
      className="home3-section11-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <h1>
        Clients’ <span>Testimonials</span>
      </h1>
      <span>Read all testimonials</span>

      <div className="home3-section11-content">
        <div className="testimonial-left">
          <img
            src={info[currentIndex].client}
            alt="Client"
            className="client-image"
          />
        </div>
        <div className="testimonial-right">
          <div className="testimonial-details">
            <p>{info[currentIndex].testimonial}</p>
            <span className="client-name">{info[currentIndex].name}</span>
            <span className="client-designation">
              {info[currentIndex].designation}
            </span>
          </div>
        </div>
      </div>

      <div className="pagination-dots">
        {info.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>

      <div className="navigation-buttons">
        <button className="prev" onClick={prevTestimonial}>
          &#60;
        </button>
        <button className="next" onClick={nextTestimonial}>
          &#62;
        </button>
      </div>
    </div>
  );
};

export default Section11;
