import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home1 from "./pages/home/home1/Home1";
import Home2 from "./pages/home/home2/Home2";
import Home3 from "./pages/home/home3/Home3";
import Home4 from "./pages/home/home4/Home4";
import Home5 from "./pages/home/home5/Home5";
import Home6 from "./pages/home/home6/Home6";
import Home7 from "./pages/home/home7/Home7";
import Home8 from "./pages/home/home8/Home8";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import Products from "./pages/products/Products";
import Industries from "./pages/industries/Industries";
import Blog from "./pages/blog/Blog";
import Career from "./pages/career/Career";
import Contact from "./pages/contact/Contact";
import Login from "./pages/login-signup/login/Login";
import Signup from "./pages/login-signup/signup/Signup";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import ChatbotIcon from "./components/chatboticon/ChatbotIcon";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home1 />} />
        <Route path="/home1" element={<Home1 />} />
        <Route path="/home2" element={<Home2 />} />
        <Route path="/home3" element={<Home3 />} />
        <Route path="/home4" element={<Home4 />} />
        <Route path="/home5" element={<Home5 />} />
        <Route path="/home6" element={<Home6 />} />
        <Route path="/home7" element={<Home7 />} />
        <Route path="/home8" element={<Home8 />} />
        <Route path="/about" element={<About />} />
        <Route path="/service/:serviceId" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
      <Footer />
      <ScrollUp />
      <ChatbotIcon />
    </BrowserRouter>
  );
}

export default App;
