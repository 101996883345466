import React from 'react';
import "./section12.scss";

const Section12 = () => {
  return (
    <div className="home3-section12-container">
      <div className="home3-section12-intro-container">
        <h1>Request A Call back</h1>
        <p>
          Please fill out the form and press the submit button. We will get back
          to you with 1-2 business days.
        </p>
      </div>
      <div className="home3-section12-form-container">
        <form method="POST">
          <div className="personal-details">
            <input type="text" placeholder="First Name" />
            <input type="text" placeholder="Last Name" />
          </div>
          <div className="personal-details">
            <input type="email" placeholder="Email" />
            <input type="text" placeholder="Mobile" />
          </div>
          <div className="message">
            <input type="text" placeholder='Subject' />
            <textarea placeholder='Message'></textarea>
          </div>
          <button className='primary-button' type='submit'>Submit Now</button>
        </form>
      </div>
    </div>
  );
}

export default Section12