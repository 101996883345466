import React from "react";
import "./footer.scss";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { FaYoutube, FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} alt="Finapsys Logo" className="footer-logo" />
          <p>
            Finapsys Consultancy Services is a premier full service business and
            financial consulting company.
          </p>
        </div>

        <div className="footer-column">
          <h3>Our Services</h3>
          <ul>
            <li>
              <Link to="/service/1">Financial Accounting</Link>
            </li>
            <li>
              <Link to="/service/2">HR Outsourcing</Link>
            </li>
            <li>
              <Link to="/service/3">IT Outsourcing</Link>
            </li>
            <li>
              <Link to="/service/4">Manpower Outsourcing</Link>
            </li>
            <li>
              <Link to="/service/5">Tax and Compliance</Link>
            </li>
            <li>
              <Link to="/service/6">Internal Audit</Link>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/industries">Industries</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/career">Career</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Contact Us</h3>
          <p>
            <FaMapMarkerAlt /> Finapsys Consultancy services Pvt Ltd
            <br />
            Kamdhenu Commerz 1704, 17th Floor,
            <br />
            Sector 14, Kharghar, Navi Mumbai: 410210
          </p>
          <p>
            <FaPhoneAlt /> +91-7738395587
          </p>
          <p>
            <FaEnvelope /> contact@finapsys.co.in
          </p>
          <div className="social-icons">
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
