import React, { useEffect, useRef } from "react";
import "./home1.scss";
import Section1 from "../../../components/home/home1/Section1";
import Section2 from "../../../components/home/home1/Section2";
import Section3 from "../../../components/home/home1/Section3";
import Section4 from "../../../components/home/home1/Section4";
import Section5 from "../../../components/home/home1/Section5";
import Section6 from "../../../components/home/home1/Section6";
import Section7 from "../../../components/home/home1/Section7";
import Section8 from "../../../components/home/home1/Section8";
import Section9 from "../../../components/home/home1/Section9";
import Section10 from "../../../components/home/home1/Section10";
import Section11 from "../../../components/home/home1/Section11";
import Section12 from "../../../components/home/home1/Section12";
import Section13 from "../../../components/home/home1/Section13";

const Home1 = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.2 }
    );

    const sections = sectionsRef.current;

    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="home-page-container">
      {[
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6,
        Section7,
        Section8,
        Section9,
        Section10,
        Section11,
        Section12,
        Section13,
      ].map((Section, index) => (
        <div
          key={index}
          className="section"
          ref={(el) => (sectionsRef.current[index] = el)}
        >
          <Section />
        </div>
      ))}
    </div>
  );
};

export default Home1;
