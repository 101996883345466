import React from "react";
import "./section6.scss";
import image1 from "../../../assets/ser1.webp";
import image2 from "../../../assets/ser2.webp";
import image3 from "../../../assets/ser3.webp";
import image4 from "../../../assets/ser4.webp";
import { SlEnergy } from "react-icons/sl";
import { GiMedicines } from "react-icons/gi";
import { BsBuildings } from "react-icons/bs";
import { LiaIndustrySolid } from "react-icons/lia";

const Section6 = () => {
  const info = [
    {
      image: image1,
      icon: <SlEnergy />,
      heading: "Energy & Power Sector",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
    {
      image: image2,
      icon: <GiMedicines />,
      heading: "Pharma & Life Science",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
    {
      image: image3,
      icon: <BsBuildings />,
      heading: "EPC & Real Estate",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
    {
      image: image4,
      icon: <LiaIndustrySolid />,
      heading: "Automotive",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
  ];

  return (
    <div className="section6-container">
      <div className="section6-intro-container">
        <span>our service area</span>
        <h1>
          industries we <span>served</span>
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore quidem
          ullam accusantium quod iste quos quas nostrum perspiciatis totam rem
          voluptatibus, repellendus numquam saepe reiciendis distinctio quia,
          nihil fugit iure!
        </p>
      </div>
      <div className="section6-grid-container">
        {info.map((item, index) => (
          <div key={index} className="section6-grid-item">
            <div className="text-content">
              <h2>
                <span className="icon">{item.icon}</span>
                {item.heading}
              </h2>
              <p>{item.description}</p>
            </div>
            <div className="image-content">
              <img src={item.image} alt="sector" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section6;
