import React, { useState, useEffect } from "react";
import "./section1.scss";
import image1 from "../../../assets/building2.avif";
import image2 from "../../../assets/16.jpg";

const Section1 = () => {
  const info = [
    {
      image: image1,
      heading1: "we help",
      heading2: "growing business",
      text: "Expand your business with innovative strategies, tailored solutions, and expert guidance to achieve sustainable growth and lasting success.",
      button: "contact us",
    },
    {
      image: image2,
      heading1: "Developing For The Future With AI",
      heading2: "digital world",
      text: "Empower your business with cutting-edge digital solutions, driving innovation, efficiency, and growth to thrive in an ever-evolving digital landscape.",
      button: "contact us",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % info.length);
    }, 20000);

    return () => clearInterval(intervalId);
  }, [info.length]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const currentItem = info[currentIndex];

  return (
    <div className="home3-section1-container">
      <div
        className="home3-section1-container-details"
        key={currentIndex}
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 40, 145, 0.8), rgba(0, 40, 145, 0) 70%), url(${currentItem.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <h2>{currentItem.heading1}</h2>
        <h1>{currentItem.heading2}</h1>
        <p>{currentItem.text}</p>
        <button className="primary-button">{currentItem.button}</button>
      </div>

      <div className="dots-container">
        {info.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Section1;
