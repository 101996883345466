import React, { useState } from "react";
import "./chatboticon.scss";

const ChatbotIcon = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="chatbot-container">
      <button className="chatbot-icon" onClick={toggleChatbot}>
        🤖
      </button>

      {isOpen && (
        <div className="chatbot-window">
          <div className="chatbot-header">
            <h3>AI Chatbot</h3>
            <button className="close-btn" onClick={toggleChatbot}>
              ✖
            </button>
          </div>
          <div className="chatbot-body">
            <p>Hello! How can I assist you today?</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotIcon;
