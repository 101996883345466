import React from "react";
import "./section7.scss";
import image1 from "../../../assets/ser1.webp";
import image2 from "../../../assets/ser2.webp";
import image3 from "../../../assets/ser3.webp";
import image4 from "../../../assets/ser4.webp";
import { SlEnergy } from "react-icons/sl";
import { GiMedicines } from "react-icons/gi";
import { BsBuildings } from "react-icons/bs";
import { LiaIndustrySolid } from "react-icons/lia";
import { NavLink } from "react-router-dom";

const Section7 = () => {
  const info = [
    {
      image: image1,
      icon: <SlEnergy />,
      heading: "Energy & Power Sector",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
    {
      image: image2,
      icon: <GiMedicines />,
      heading: "Pharma & Life Science",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
    {
      image: image3,
      icon: <BsBuildings />,
      heading: "EPC & Real Estate",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
    {
      image: image4,
      icon: <LiaIndustrySolid />,
      heading: "Automotive",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis doloribus reprehenderit optio, in porro animi?",
    },
  ];

  return (
    <div className="home3-section7-container">
      <h1>
        Our <span>Cases</span>
      </h1>
      <span>View our works</span>
      <div className="home3-section7-container-grid">
        {info.map((item, index) => (
          <NavLink to="#">
            <div className="home3-section7-grid-details">
              <img src={item.image} alt="itemImage" />
              <h3>
                {item.icon}
                {item.heading}
              </h3>
              <p>{item.description}</p>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Section7;
