import React, { useState } from "react";
import "./section5.scss";
import { SiGooglecontaineroptimizedos } from "react-icons/si";
import { SiGoogledomains } from "react-icons/si";
import { IoIosAnalytics } from "react-icons/io";
import { PiExcludeLight } from "react-icons/pi";

import image1 from "../../../assets/services-img1.png";
import image2 from "../../../assets/services-img1.png";
import image3 from "../../../assets/services-img1.png";
import image4 from "../../../assets/services-img1.png";

const Section5 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const info = [
    {
      icon: <SiGooglecontaineroptimizedos />,
      heading: "Operations Optimization",
      image: image1,
      description:
        "Our industry leading practices help our clients achieve best-in class industry standards and process optimization while ensuring holistic transformation and fast, smooth execution with minimum risk.",
      buttonText: "Read More",
    },
    {
      icon: <SiGoogledomains />,
      heading: "Industry and domain expertise",
      image: image2,
      description:
        "Our subject matter experts have deep cross- industry and domain specific expertise around various facets of the F&A, Tax, Compliance, HR etc., functions.",
      buttonText: "Read More",
    },
    {
      icon: <IoIosAnalytics />,
      heading: "Analytics and Insights",
      image: image3,
      description:
        "Our advanced analytics models enables firms to improve collaboration with their external partners, and effectively measure and track KPIs for effective budgeting and target setting.",
      buttonText: "Read More",
    },
    {
      icon: <PiExcludeLight />,
      heading: "Delivery excellence",
      image: image4,
      description:
        "We have a proven track record of optimizing the F&A functions of leading companies in industries such as telecom, infra, automotive, IT and manufacturing.",
      buttonText: "Read More",
    },
  ];

  return (
    <div className="section5-container">
      <div className="heading-intro">
        <h1>
          The <span>Finapsys</span> Advantage
        </h1>
        <p>
          We help you realize value beyond cost savins through multiple
          transformation projects. Our Value based approach helps enterprises
          achieve significant and sustained business outcomes. we align with
          your strategic objectives & priorities to identify top business
          metrices and deliver signigicant impact through:
        </p>
      </div>
      <div className="section5-tab-container">
        <div className="tabs">
          {info.map((item, index) => (
            <button
              key={index}
              className={`tab ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              <span className="tab-icon">{item.icon}</span>
              {item.heading}
            </button>
          ))}
        </div>
        <div className="tab-content">
          <div className="section5-info-card" key={activeTab}>
            <img src={info[activeTab].image} alt="tabImage" />
            <p>{info[activeTab].description}</p>
            <button className="primary-button">
              {info[activeTab].buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
