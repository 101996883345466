import React, { useState } from "react";
import "./section6.scss";
import image from "../../../assets/15.png";
import { PiPlusSquareLight, PiMinusSquareLight } from "react-icons/pi";

const Section6 = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);

  const faqs = [
    {
      qsn: "What services do you offer?",
      ans: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, search for 'lorem ipsum' will uncover",
    },
    {
      qsn: "How experienced is your team?",
      ans: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, search for 'lorem ipsum' will uncover",
    },
    {
      qsn: "Can you provide examples of past projects?",
      ans: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, search for 'lorem ipsum' will uncover",
    },
  ];

  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="home2-section6-container">
      <div className="home2-section6-faq-container">
        <span>your answer</span>
        <h1>
          Have Any <span className="qsn-span">Question</span> Please?
        </h1>
        {faqs.map((faq, index) => (
          <div className="qsn-ans" key={index}>
            <div
              className={`qsn-heading ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => handleToggle(index)}
            >
              {expandedIndex === index ? (
                <PiMinusSquareLight className="expand-icon" />
              ) : (
                <PiPlusSquareLight className="expand-icon" />
              )}
              <h3>{faq.qsn}</h3>
            </div>
            {expandedIndex === index && <p>{faq.ans}</p>}
          </div>
        ))}
      </div>
      <div className="home2-section6-image-container">
        <img src={image} alt="faqImg" />
      </div>
    </div>
  );
};

export default Section6;
