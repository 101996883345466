import React from "react";
import "./section9.scss";
import image from "../../../assets/quote1.jpg";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

const Section9 = () => {
  return (
    <div className="home3-section9-container">
      <img src={image} alt="quoteImg" />
      <div className="quote-container">
        <p className="quote">
          <FaQuoteLeft className="quote-symbol" />
          Our goal is to be at the heart of the financial services industry as
          businesses expand across the globe.
          <FaQuoteRight className="quote-symbol" />
        </p>
        <p className="author">John Smith</p>
        <span className="designation">CEO of Finapsys</span>
      </div>
    </div>
  );
};

export default Section9;
