import React, { useEffect, useState, useMemo } from "react";
import "./section8.scss";

const Section8 = () => {
  const info = useMemo(
    () => [
      { value: 6000, text: "All Time Clients" },
      { value: 979, text: "Clients in This Year" },
      { value: 300, text: "New Projects" },
      { value: 35, text: "Average Profit Increased (%)" },
    ],
    []
  );

  const [counts, setCounts] = useState(info.map(() => 0));

  useEffect(() => {
    const animateCounter = (targetValue, index) => {
      let start = 0;
      const increment = targetValue / 100;

      const updateCount = () => {
        start += increment;
        if (start >= targetValue) {
          start = targetValue;
        }
        setCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          newCounts[index] = Math.ceil(start);
          return newCounts;
        });
        if (start < targetValue) {
          requestAnimationFrame(updateCount);
        }
      };

      updateCount();
    };

    info.forEach((item, index) => animateCounter(item.value, index));
  }, [info]);

  return (
    <div className="home3-section8-container">
      <h1>
        Success <span>Stories</span>
      </h1>
      <span>What we have done</span>
      <div className="home3-section8-grid-container">
        {info.map((item, index) => (
          <div key={index} className="home3-section8-grid-details">
            <h1>
              {counts[index]}
              {item.value === 35 ? "%" : ""}
            </h1>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section8;
