import React from "react";
import "./section13.scss";

const Section13 = () => {
  return (
    <div className="section13-container">
      <div className="section13-heading-intro">
        <h1>Ask for the quotation</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eius
          nesciunt numquam magnam odio quasi.
        </p>
      </div>
      <div className="section13-details">
        <form method="POST">
          <div className="input-wrapper">
            <input type="text" placeholder="Enter your email" />
          </div>
          <button className="primary-button" type="submit">
            Get Quote
          </button>
        </form>
      </div>
    </div>
  );
};

export default Section13;
