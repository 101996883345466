import React from "react";
import "./section2.scss";
import image1 from "../../../assets/handshake.jpg";
import image2 from "../../../assets/team.webp";
import image3 from "../../../assets/analyse.webp";
import { CiTimer } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { TbBrandGoogleAnalytics } from "react-icons/tb";

const Section2 = () => {
  const info = [
    {
      icon: <CiTimer />,
      heading: "On time service",
      description:
        "Transforming distribution and marketing with key capabilities in customer insight and analytics.",
      image: image1,
    },
    {
      icon: <FaUsers />,
      heading: "A team of professionals",
      description:
        "Transforming distribution and marketing with key capabilities in customer insight and analytics.",
      image: image2,
    },
    {
      icon: <TbBrandGoogleAnalytics />,
      heading: "Analyze your business",
      description:
        "Transforming distribution and marketing with key capabilities in customer insight and analytics.",
      image: image3,
    },
  ];

  return (
    <div className="home3-section2-container">
      <div className="home3-section2-grid-container">
        {info.map((item, index) => (
          <div
            className="home3-section2-card"
            key={index}
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h3>
              {item.icon}
              {item.heading}
            </h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section2;
