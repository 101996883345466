import React from "react";
import "./section5.scss";
import image1 from "../../../assets/s1.svg";
import image2 from "../../../assets/s2.svg";
import image3 from "../../../assets/s3.svg";
import image4 from "../../../assets/s4.svg";

const Section5 = () => {
  const info = [
    {
      image: image1,
      heading: "Web Development",
      description:
        "Research ipsum dolor sit consec tetur sed diam in the aliquam tempor",
      buttonText: "Read More",
    },
    {
      image: image2,
      heading: "Big Data Analytics",
      description:
        "Create ipsum dolor sit consec tetur sed diam in the aliquam tempor",
      buttonText: "Read More",
    },
    {
      image: image3,
      heading: "Cyber security",
      description:
        "Develope ipsum dolor sit consec tetur sed diam in the aliquam tempor",
      buttonText: "Read More",
    },
    {
      image: image4,
      heading: "Mobile App",
      description:
        "Shop ipsum dolor sit consec tetur Malesuada sed diam in the aliquam tempor",
      buttonText: "Read More",
    },
  ];

  return (
    <div className="home2-section5-container">
      <div className="home2-section5-details-container">
        <span>what we offer</span>
        <h1>
          It <span className="service-span">Service</span> Refers To The
          Provision Of Support
        </h1>
      </div>
      <div className="home2-section5-grid-container">
        {info.map((item, index) => (
          <div className="home2-section5-card">
            <img src={item.image} alt="itemImage" className="item-icon" />
            <h2 className="item-heading">{item.heading}</h2>
            <p className="item-description">{item.description}</p>
            <button className="primary-button">{item.buttonText}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section5;
