import React, { useState } from "react";
import "./section10.scss";
import image1 from "../../../assets/insight1.webp";
import image2 from "../../../assets/insight2.webp";
import image3 from "../../../assets/insight3.webp";
import image4 from "../../../assets/insight4.webp";

const Section10 = () => {
  const info = [
    {
      image: image1,
      date: "March 11, 2024",
      designation: "Admin",
      heading: "Drawing feasibility from SWOT analysis",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
    {
      image: image2,
      date: "April 11, 2024",
      designation: "Admin",
      heading: "6A's of strategy implementation",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
    {
      image: image3,
      date: "May 11, 2024",
      designation: "Admin",
      heading: "4-C's of work from home",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
    {
      image: image4,
      date: "June 11, 2024",
      designation: "Admin",
      heading: "Cost Reduction Strategy for Companies",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = info.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(info.length / itemsPerPage);

  const handleDotClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="home3-section10-container">
      <h1>
        Latest <span>Blogs</span>
      </h1>
      <span>Read all blogs</span>
      <div className="home3-section10-grid-container">
        {currentItems.map((item, index) => (
          <div className="home3-section10-grid-details" key={index}>
            <img src={item.image} alt="blogImage" />
            <span>
              {item.date} | {item.designation}
            </span>
            <h3>{item.heading}</h3>
            <p>
              {item.text.length > 150
                ? item.text.slice(0, 150) + "..."
                : item.text}
            </p>
          </div>
        ))}
      </div>

      <div className="pagination-dots">
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index}
            className={`dot ${currentPage === index + 1 ? "active" : ""}`}
            onClick={() => handleDotClick(index + 1)}
          />
        ))}
      </div>
    </div>
  );
};

export default Section10;
