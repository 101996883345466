import React, { useState, useEffect } from "react";
import "./section1.scss";
import slide1Gif from "../../../assets/slide1.gif";
import slide2Gif from "../../../assets/slide2.gif";
import slide3Gif from "../../../assets/slide3.gif";
import slide4Gif from "../../../assets/slide4.gif";
import slide5Gif from "../../../assets/slide5.gif";
import slide6Gif from "../../../assets/slide6.gif";
import slide7Gif from "../../../assets/slide7.gif";
import AI from "../../../assets/AI.gif";
import grow from "../../../assets/grow.gif";

const Section1 = () => {
  const info = [
    {
      image: slide2Gif,
      heading: "Mobile App Development",
      button: "Get Started",
      description:
        "Mobile app development creates innovative, user-friendly solutions for iOS, Android, and cross-platform needs. It blends cutting-edge tech, seamless design, and functionality to deliver high-performance apps for diverse industries.",
    },
    {
      image: slide1Gif,
      heading: "Web App Development",
      button: "Get Started",
      description:
        "Web app development builds responsive, scalable, and secure solutions tailored to your needs. Using the latest tech, it delivers dynamic single-page apps or complex systems for seamless user experiences and business growth.",
    },
    {
      image: slide3Gif,
      heading: "Software Development",
      button: "Get Started",
      description:
        "Software development crafts tailored solutions to streamline processes and drive innovation. Combining advanced technology and user-focused design, it delivers scalable, efficient, and secure tools for diverse business needs.",
    },
    {
      image: slide4Gif,
      heading: "AR/VR App Development",
      button: "Get Started",
      description:
        "AR/VR development creates immersive experiences by blending virtual and real worlds. From interactive training to virtual tours, it leverages cutting-edge tech to enhance engagement, learning, and entertainment across industries.",
    },
    {
      image: slide5Gif,
      heading: "API Development",
      button: "Get Started",
      description:
        "API development enables seamless integration and communication between systems. By building secure, scalable, and efficient APIs, it empowers businesses to connect applications, enhance functionality, and drive innovation.",
    },
    {
      image: slide6Gif,
      heading: "MVP Development",
      button: "Get Started",
      description:
        "MVP development focuses on creating a minimum viable product with core features to quickly test and validate a business idea. It allows startups to gather real user feedback, reduce risks, and iterate efficiently for faster market entry and growth.",
    },
    {
      image: slide7Gif,
      heading: "SaaS Development",
      button: "Get Started",
      description:
        "SaaS development creates cloud-based software solutions, offering scalable, subscription-based services. It enables businesses to access powerful tools, improve efficiency, and drive growth without complex installations.",
    },
    {
      image: AI,
      heading: "AI Development",
      button: "Contact Us",
      description:
        "AI development leverages machine learning, natural language processing, and deep learning to create intelligent solutions. From automation to predictive analytics, AI transforms industries by enhancing decision-making, optimizing processes, and creating personalized experiences.",
    },
    {
      image: grow,
      heading: "Grow Your Business",
      button: "Contact Us",
      description:
        "Unlock your business potential with innovative solutions. From cutting-edge technology to tailored strategies, we empower your growth, streamline processes, and help you scale successfully in a competitive market.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % info.length);
    }, 15000);

    return () => clearInterval(interval);
  }, [info.length]);

  const { image, heading, button, description } = info[currentIndex];

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="home4-section1-container">
      <div className="home4-section1-details">
        <div className="home4-section1-intro">
          <div className="span-shape">
            <div className="shape"></div>
            <span>
              {currentIndex === info.length - 2
                ? "Know more about our AI development"
                : currentIndex === info.length - 1
                ? "Grow Your Business"
                : "We Develop"}
            </span>
          </div>
          <div>
            <h3>{heading}</h3>
            <p>{description}</p>
            <button className="primary-button parallelogram">{button}</button>
          </div>
        </div>
        <div className="home4-section1-image">
          <img src={image} alt={heading} />
        </div>
      </div>

      <div className="dots-container">
        {info.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Section1;
