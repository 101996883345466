import React from "react";
import "./section10.scss";
import image1 from "../../../assets/insight1.webp";
import image2 from "../../../assets/insight2.webp";
import image3 from "../../../assets/insight3.webp";

const Section10 = () => {
  const info = [
    {
      image: image1,
      date: "March 11, 2024",
      designation: "Admin",
      heading: "Drawing feasibility from SWOT analysis",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
    {
      image: image2,
      date: "April 11, 2024",
      designation: "Admin",
      heading: "6A's of strategy implementation",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
    {
      image: image3,
      date: "May 11, 2024",
      designation: "Admin",
      heading: "4-C's of work from home",
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere, placeat exercitationem! Quam ipsam dicta laudantium molestias sunt, fuga natus reiciendis omnis enim porro inventore explicabo repellat deleniti nobis ea ad nesciunt eligendi magnam nisi illum? Dolorum eveniet ipsam dolores quod aperiam. Ad illo odit est, inventore quaerat beatae aspernatur a nulla quibusdam laudantium corrupti consequuntur deserunt voluptates, modi quam consectetur!",
    },
  ];

  const truncateText = (text, limit) => {
    return text.length > limit ? text.slice(0, limit) + "..." : text;
  };

  return (
    <div className="section10-container">
      <div className="section10-heading-intro">
        <h1>Lorem ipsum dolor sit amet.</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
          iste reiciendis exercitationem eius accusamus modi non cum natus.
        </p>
      </div>
      <div className="section10-grid-container">
        {info.map((item, index) => (
          <div className="section10-card" key={index}>
            <img src={item.image} alt="blogImg" />
            <span>
              {item.date} | {item.designation}
            </span>
            <h2>{item.heading}</h2>
            <p>{truncateText(item.text, 150)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section10;
