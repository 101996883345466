import React from "react";
import "./section3.scss";
import shape from "../../../assets/14.png";
import image from "../../../assets/13.png";
import ProgressBar from "../../progressbar/ProgressBar";
import { CiGlobe } from "react-icons/ci";
import { IoMdCloudOutline } from "react-icons/io";

const Section3 = () => {
  return (
    <div className="home2-section3-container">
      <div className="home2-section3-image-container">
        <img src={shape} alt="shape" className="shape" />
        <img src={image} alt="H-img" className="image" />
      </div>
      <div className="home2-section3-details-container">
        <p className="home2-about-heading">about us</p>
        <h1 className="home2-about-h1">
          transforming businesses through <span>software</span> innovation
        </h1>
        <span>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, nemo
          explicabo! Molestiae eveniet voluptatibus dolor nam commodi voluptate
          vel laboriosam.
        </span>
        <div className="home2-section3-progress-bar-container">
          <ProgressBar
            icon={<CiGlobe />}
            progress={90}
            label="Web Development"
          />
          <ProgressBar
            icon={<IoMdCloudOutline />}
            progress={80}
            label="Cloud Solution"
          />
        </div>
      </div>
    </div>
  );
};

export default Section3;
