import React, { useEffect, useState, useRef } from "react";
import "./progressbar.scss";

const ProgressBar = ({ icon, progress, label }) => {
  const [width, setWidth] = useState(0);
  const progressRef = useRef(null);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting) {
        setWidth(progress);
      } else {
        setWidth(0);
      }
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.2,
    });

    const currentRef = progressRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [progress]);

  return (
    <div className="progress-bar-container" ref={progressRef}>
      <div className="progress-info-icon">{icon}</div>
      <div className="progress-bar-details">
        <div className="progress-details">
          <span>{label}</span>
          <span>{progress}%</span>
        </div>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${width}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
