import React, { useState, useEffect, useCallback } from "react";
import "./section11.scss";
import shape from "../../../assets/right-shape1.png";
import client1 from "../../../assets/client1.jpg";
import client2 from "../../../assets/client2.jpg";
import client3 from "../../../assets/client3.jpg";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

const Section11 = () => {
  const info = [
    {
      shape: shape,
      client: client1,
      testimonial:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, inventore laboriosam. Eaque quidem tempora molestias sit a natus animi sint iusto accusantium? Aliquam totam eius magnam a reiciendis natus maxime tempore suscipit ut expedita obcaecati voluptate, hic quas blanditiis, porro cumque dicta, ratione molestias? Tempore quia asperiores fuga laborum rem?",
      name: "Harish Jain",
      designation: "C.E.O. of Retail Chain",
    },
    {
      shape: shape,
      client: client2,
      testimonial:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, inventore laboriosam. Eaque quidem tempora molestias sit a natus animi sint iusto accusantium? Aliquam totam eius magnam a reiciendis natus maxime tempore suscipit ut expedita obcaecati voluptate, hic quas blanditiis, porro cumque dicta, ratione molestias? Tempore quia asperiores fuga laborum rem?",
      name: "John Doe",
      designation: "Business Analyst",
    },
    {
      shape: shape,
      client: client3,
      testimonial:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, inventore laboriosam. Eaque quidem tempora molestias sit a natus animi sint iusto accusantium? Aliquam totam eius magnam a reiciendis natus maxime tempore suscipit ut expedita obcaecati voluptate, hic quas blanditiis, porro cumque dicta, ratione molestias? Tempore quia asperiores fuga laborum rem?",
      name: "Alex",
      designation: "Software Enginner",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % info.length);
  }, [info.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? info.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="section11-container">
      <div className="section11-heading-intro">
        <span>our testimonial</span>
        <h1>
          What <span>People</span> Say
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
          voluptates voluptatum autem et fugit, id sapiente.
        </p>
      </div>
      <div className="testimonial-container">
        <button className="prev-btn" onClick={prevSlide}>
          <GrPrevious />
        </button>
        <div className="testimonial-details">
          <img src={shape} alt="shape" className="shape" />
          <p>"{info[currentIndex].testimonial}"</p>
          <span className="name">{info[currentIndex].name}</span>
          <span className="designation">{info[currentIndex].designation}</span>
          <img
            src={info[currentIndex].client}
            alt="client"
            className="client"
          />
        </div>
        <button className="next-btn" onClick={nextSlide}>
          <GrNext />
        </button>
      </div>
    </div>
  );
};

export default Section11;
