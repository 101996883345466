import React from "react";
import "./section12.scss";
import logo1 from "../../../assets/logo1.webp";
import logo2 from "../../../assets/logo2.webp";
import logo3 from "../../../assets/logo3.webp";
import logo4 from "../../../assets/logo4.webp";
import logo5 from "../../../assets/logo5.webp";
import logo6 from "../../../assets/logo6.webp";
import logo7 from "../../../assets/logo7.webp";
import logo8 from "../../../assets/logo8.webp";
import logo9 from "../../../assets/logo9.webp";
import logo10 from "../../../assets/logo10.webp";

const Section12 = () => {
  const logos = [
    {
      logo: logo1,
    },
    {
      logo: logo2,
    },
    {
      logo: logo3,
    },
    {
      logo: logo4,
    },
    {
      logo: logo5,
    },
    {
      logo: logo6,
    },
    {
      logo: logo7,
    },
    {
      logo: logo8,
    },
    {
      logo: logo9,
    },
    {
      logo: logo10,
    },
  ];
  
  return (
    <div className="section12-container">
      <div className="section12-heading-intro">
        <h1>Lorem, ipsum dolor.</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
          voluptates soluta quae tempora consequuntur consequatur?
        </p>
      </div>
      <div className="section12-grid-container">
        {logos.map((logo, index) => (
          <img src={logo.logo} alt="logo" key={index} className="logo" />
        ))}
      </div>
    </div>
  );
};

export default Section12;
