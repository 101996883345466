import React from "react";
import "./section1.scss";
import { BsRobot } from "react-icons/bs";
import { GiBrain } from "react-icons/gi";
import { GiArtificialIntelligence } from "react-icons/gi";

const Section1 = () => {
  const info = [
    {
      icon: <BsRobot />,
      heading: "Lorem ipsum dolor sit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, fuga.",
    },
    {
      icon: <GiBrain />,
      heading: "Lorem ipsum dolor sit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, fuga.",
    },
    {
      icon: <GiArtificialIntelligence />,
      heading: "Lorem ipsum dolor sit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, fuga.",
    },
  ];

  return (
    <div className="section1-container">
      <h1>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, fuga.
        Aut mollitia placeat cupiditate dignissimos ipsa voluptatem tempora
        eveniet, quibusdam exercitationem consectetur nostrum deleniti pariatur
        quisquam harum eos! Aspernatur voluptatem nesciunt, placeat perferendis
        expedita deserunt voluptate ad pariatur quia minus.
      </p>
      <div className="btn-container">
        <button className="btn-quote">Request Quote</button>
        <button className="btn-free">Get Started Free</button>
      </div>
      <div className="grid-info">
        {info.map((item, index) => (
          <div className="info-item" key={index}>
            <div className="icon">{item.icon}</div>
            <h3 className="heading">{item.heading}</h3>
            <p className="description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section1;
