import React, { useState, useRef } from "react";
import "./header.scss";
import {
  FaLanguage,
  FaBars,
  FaTimes,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import logo from "../../assets/logo.png";
import { CiGlobe } from "react-icons/ci";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import rightPanelTexture from "../../assets/texture.avif";

const Header = () => {
  const [location, setLocation] = useState("India");
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showLeftPanel, setShowLeftPanel] = useState(false);

  const rightPanelRef = useRef(null);
  const leftPanelRef = useRef(null);

  const locationData = {
    India: {
      email: "contact@finapsys.co.in",
      phone: "+91-7738395587",
      language: "en",
    },
    UAE: {
      email: "contact@uae.com",
      phone: "+971-7738395587",
      language: "ar",
    },
    Europe: {
      email: "contact@europe.com",
      phone: "+44-7738395587",
      language: "en",
    },
    USA: { email: "contact@usa.com", phone: "+1-7738395587", language: "en" },
  };

  const menuItems = [
    "Home",
    "About Us",
    "Services",
    "Industries",
    "Blogs",
    "Career",
    "Contact",
  ];

  const servicesData = [
    {
      title: "Mobile Development",
      links: [
        "React Native Development",
        "Flutter App Development",
        "Native Android Development",
        "Native iOS App Development",
      ],
    },
    {
      title: "Software Development",
      links: ["Software Development"],
    },
    {
      title: "Blockchain Development",
      links: ["Blockchain Development Services"],
    },
    {
      title: "AR/VR Development",
      links: ["AR/VR Development"],
    },
    {
      title: "Web Development",
      links: [
        "React Development",
        "Node.js Development",
        "Angular Development",
        "Wordpress Development",
        "Drupal Development",
        ".NET Development",
        "PHP Development",
        "Python Development",
        "Java Development",
        "Laravel Development",
        "TYPO3 Development",
      ],
    },
    {
      title: "e-Commerce Development",
      links: [
        "Magento Development",
        "WooCommerce Development",
        "Shopify Development",
      ],
    },
    {
      title: "Digital Marketing",
      links: [
        "SEO Services",
        "SMM Services",
        "PPC Services",
        "App Marketing Services",
        "Content Marketing Services",
      ],
    },
    {
      title: "Full Stack Development",
      links: ["Full Stack Development"],
    },
    {
      title: "IT Staff Augmentation",
      links: ["IT Staff Augmentation Services"],
    },
    {
      title: "MVP Development",
      links: ["MVP Development"],
    },
    {
      title: "API Development",
      links: ["API Development"],
    },
    {
      title: "QA Testing",
      links: ["QA & Testing Services"],
    },
    {
      title: "Cyber Security Services",
      links: ["Cyber Security Services"],
    },
  ];

  const menuItemDetails = {
    Home: {
      title: "Home",
      description:
        "Explore Finapsys's home base—a comprehensive starting point to understand our values, services, and commitment to driving impactful business solutions. From here, navigate seamlessly through our expertise, resources, and latest insights tailored to support and elevate businesses worldwide.",
      links: [
        { name: "Homepage 1", url: "/home1" },
        { name: "Homepage 2", url: "/home2" },
        { name: "Homepage 3", url: "/home3" },
        { name: "Homepage 4", url: "/home4" },
        { name: "Homepage 5", url: "/home5" },
        { name: "Homepage 6", url: "/home6" },
        { name: "Homepage 7", url: "/home7" },
        { name: "Homepage 8", url: "/home8" },
      ],
    },
    "About Us": {
      title: "About Us",
      description: "Learn more about our company and mission.",
    },
    Services: {
      title: "Our Services",
      description:
        "Discover the suite of specialized services designed to fuel your business growth. At Finapsys, we provide innovative and strategic solutions across finance, technology, and consultancy tailored to meet the unique needs of startups, small businesses, and established enterprises. Our expert team brings cutting-edge insights and actionable strategies to optimize your operations, drive digital transformation, and ensure sustainable success in a competitive landscape.",
    },
    Industries: {
      title: "Industries We Serve",
      description: "Explore the industries we specialize in and our expertise.",
    },
    Blogs: {
      title: "Our Blog",
      description: "Read articles and updates from our team on various topics.",
    },
    Career: {
      title: "Join Our Team",
      description: "Check out the latest career opportunities with us.",
    },
    Contact: {
      title: "Contact Us",
      description: "Get in touch with us for inquiries or support.",
    },
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      setShowLeftPanel(false);
    }
  };

  const handleMenuHover = (item) => {
    setHoveredItem(item);
    setShowLeftPanel(item === "Home" || item === "Services");
  };

  const handlePanelEnter = () => {
    setShowLeftPanel(true);
  };

  return (
    <header>
      <div className="logo">
        <img src={logo} alt="logoImg" />
      </div>
      <div className="menus">
        <div className="location">
          <p>
            <CiGlobe className="icon" />
          </p>
          <select
            className="navbar__location"
            value={location}
            onChange={handleLocationChange}
          >
            {Object.keys(locationData).map((loc) => (
              <option key={loc} value={loc}>
                {loc}
              </option>
            ))}
          </select>
        </div>

        <div className="navbar__contact">
          <p>
            <MdOutlineMarkEmailUnread className="icon" />
          </p>
          <span>{locationData[location].email}</span>
        </div>

        <div className="navbar__phone">
          <p>
            <FiPhone className="icon" />
          </p>
          <span>{locationData[location].phone}</span>
        </div>

        <div className="navbar__language">
          <FaLanguage className="icon" />
          <span>
            {locationData[location].language === "ar" ? "Arabic" : "English"}
          </span>
        </div>
      </div>

      <div className="menu-icon" onClick={toggleMenu}>
        <button>{menuOpen ? <FaTimes /> : <FaBars />}</button>
      </div>

      <div
        ref={rightPanelRef}
        className={`right-panel ${menuOpen ? "open" : ""}`}
        onMouseEnter={() => setShowLeftPanel(false)}
        style={{ backgroundImage: `url(${rightPanelTexture})` }}
      >
        <div className="right-panel-content">
          <img src={logo} alt="logoImg" />
          {menuItems.map((item) => (
            <h3 key={item} onMouseEnter={() => handleMenuHover(item)}>
              {item}
            </h3>
          ))}
          <hr />
          <div className="social-icons">
            <Link
              to="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </Link>
            <Link
              to="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter />
            </Link>
            <Link
              to="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </Link>
            <Link
              to="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </Link>
          </div>
        </div>
      </div>

      <div
        ref={leftPanelRef}
        className={`left-panel ${showLeftPanel ? "visible" : ""}`}
        onMouseEnter={handlePanelEnter}
      >
        <div className="left-panel-content">
          {hoveredItem ? (
            <>
              <h1>{menuItemDetails[hoveredItem].title}</h1><hr />
              <p>{menuItemDetails[hoveredItem].description}</p><hr />
              {hoveredItem === "Home" && (
                <div className="homepage-links">
                  <ul>
                    {menuItemDetails["Home"].links.map((link, index) => (
                      <li key={index}>
                        <Link to={link.url} onClick={toggleMenu}>
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {hoveredItem === "Services" && (
                <div className="services-links">
                  <div className="column1">
                    <ul>
                      <li>
                        <h3>{servicesData[0].title}</h3>
                        <ul>
                          {servicesData[0].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[1].title}</h3>
                        <ul>
                          {servicesData[1].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[2].title}</h3>
                        <ul>
                          {servicesData[2].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[3].title}</h3>
                        <ul>
                          {servicesData[3].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="column2">
                    <ul>
                      <li>
                        <h3>{servicesData[4].title}</h3>
                        <ul>
                          {servicesData[4].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="column3">
                    <ul>
                      <li>
                        <h3>{servicesData[5].title}</h3>
                        <ul>
                          {servicesData[5].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[6].title}</h3>
                        <ul>
                          {servicesData[6].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[7].title}</h3>
                        <ul>
                          {servicesData[7].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="column4">
                    <ul>
                      <li>
                        <h3>{servicesData[8].title}</h3>
                        <ul>
                          {servicesData[8].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[9].title}</h3>
                        <ul>
                          {servicesData[9].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[10].title}</h3>
                        <ul>
                          {servicesData[10].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[11].title}</h3>
                        <ul>
                          {servicesData[11].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <h3>{servicesData[12].title}</h3>
                        <ul>
                          {servicesData[12].links.map((link) => (
                            <li key={link}>
                              <Link to="#" onClick={toggleMenu}>
                                {link}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p>Select a menu item to see the details.</p>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
