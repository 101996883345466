import React from "react";
import "./section1.scss";
import shape from "../../../assets/shape2.png";
import image from "../../../assets/1.png";;

const Section1 = () => {
  return (
    <div className="home2-section1-container">
      <div className="section1-top-container">
        <div className="section1-intro-container">
          <div className="ai-heading">
            <p>
              Developing For The Future With <span>AI</span>
            </p>
          </div>
          <h1>
            Innovative solutions For A <span>Digital</span> World
          </h1>
          <form method="POST">
            <input type="email" placeholder="Enter your email" />
            <button type="submit" className="primary-button">
              Request Demo
            </button>
          </form>
          <img src={shape} alt="shape" className="globe_shape" />
        </div>
        <div className="section1-image-container">
          <img src={image} alt="image1" className="image1" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
