import React from "react";
import "./home4.scss";
import Section1 from "../../../components/home/home4/Section1";

const Home4 = () => {
  return (
    <div className="home-page-container">
      <Section1 />
    </div>
  );
};

export default Home4;
