import React, { useState } from "react";
import "./section13.scss";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import logo1 from "../../../assets/logo1.webp";
import logo2 from "../../../assets/logo2.webp";
import logo3 from "../../../assets/logo3.webp";
import logo4 from "../../../assets/logo4.webp";
import logo5 from "../../../assets/logo5.webp";
import logo6 from "../../../assets/logo6.webp";
import logo7 from "../../../assets/logo7.webp";
import logo8 from "../../../assets/logo8.webp";
import logo9 from "../../../assets/logo9.webp";
import logo10 from "../../../assets/logo10.webp";

const Section13 = () => {
  const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
  ];
  const logosPerPage = 5;
  const totalPages = Math.ceil(logos.length / logosPerPage);
  const [currentPage, setCurrentPage] = useState(0);

  const goToNextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const goToPrevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const setPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const displayedLogos = logos.slice(
    currentPage * logosPerPage,
    currentPage * logosPerPage + logosPerPage
  );

  return (
    <div className="home3-section13-container">
      <h1>Our <span>Clients</span></h1>
      <button onClick={goToPrevPage} className="nav-button prev-button">
        <BiChevronLeft size={30} />
      </button>

      <div className="logo-display">
        {displayedLogos.map((logo, index) => (
          <div className="logo" key={index}>
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>

      <button onClick={goToNextPage} className="nav-button next-button">
        <BiChevronRight size={30} />
      </button>

      <div className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentPage ? "active" : ""}`}
            onClick={() => setPage(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Section13;
