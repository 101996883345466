import React from "react";
import "./section5.scss";
import image from "../../../assets/17.png";
import { TiTick } from "react-icons/ti";
import video from "../../../assets/videoplayback.mp4";

const Section5 = () => {
  return (
    <div className="home3-section5-container">
      <div className="home3-section5-container-grid">
        <div className="grid-details1">
          <video autoPlay muted loop className="background-video">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="grid-details2">
          <span>do you know that</span>
          <h1>We are trusted by more than 5,000 clients</h1>
          <p>
            A wonderful serenity has taken possession of my entire soul, like
            these sweet mornings of spring which I enjoy with my whole heart. I
            am alone, and feel the charm of existence in this spot, which was
            created for the bliss of souls like mine. More than 25 years of
            experience working in the industry has enabled us to build our
            services and solutions in strategy, consulting, digital.
          </p>
          <button className="primary-button">Read Our Story</button>
        </div>
        <div className="grid-details3">
          <h1>We are expert in this field</h1>
          <span>
            Yet I feel that I never was a greater artist than now. When, while
            the lovely valley teems.
          </span>
          <p>
            <TiTick />
            Premium services and beyond your expectation
          </p>
          <p>
            <TiTick />
            Get the best support among all venders
          </p>
          <p>
            <TiTick />
            Fully responsive and works great on all major devices
          </p>
          <p>
            <TiTick />
            Tons of admin panel options
          </p>
          <button className="primary-button">Read Our Story</button>
        </div>
        <div className="grid-details4">
          <img src={image} alt="emp" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
