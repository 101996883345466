import React from "react";
import "./section6.scss";
import video from "../../../assets/Concho-Video-Bg-05.mp4";

const Section6 = () => {
  return (
    <div className="home3-section6-container">
      <h1>
        Our <span>Services</span>
      </h1>
      <span>The services we provide</span>
      <div className="video-background">
        <video autoPlay muted loop className="background-video">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay-content">
          <h1>Digital-Friendly Strategic Solutions For Modern World</h1>
          <button className="primary-button">All Services</button>
        </div>
      </div>
    </div>
  );
};

export default Section6;
