import React from "react";
import "./section8.scss";
import { FaTelegramPlane } from "react-icons/fa";
import { PiHandshakeFill } from "react-icons/pi";
import { FaIndustry } from "react-icons/fa";

const Section8 = () => {
  const info = [
    {
      icon: <FaTelegramPlane />,
      heading: "Startup Business",
      description:
        "Finapsys provides financial, analytical and technological expertise, along with experience in business strategy that enable entrepreneurs to make better decisions in the startup period, across a wide swath of operations and create long term value.",
    },
    {
      icon: <PiHandshakeFill />,
      heading: "New & Early Stage Business",
      description:
        "Finapsys provides its services to new and early-stage companies to move them through their first and second years of operations.",
    },
    {
      icon: <FaIndustry />,
      heading: "Establish Business",
      description:
        "Finapsys provides it clientele financial and technology services through the life of their initial five-year business plans as they move through their business operations.",
    },
  ];

  return (
    <div className="section8-container">
      <div className="section8-heading-intro">
        <span>our solution</span>
        <h1>our <span>customized</span> solution</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Explicabo
          consequatur unde praesentium quasi. Inventore dicta a obcaecati
          doloremque odit amet, accusantium voluptatibus qui cumque quas!
        </p>
      </div>
      <div className="section8-grid-container">
        {info.map((item, index) => (
          <div className="card" key={index}>
            <div className="card-icon">{item.icon}</div>
            <h2 className="card-heading">{item.heading}</h2>
            <p className="card-description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section8;
