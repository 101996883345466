import React from "react";
import "./section4.scss";
import image from "../../../assets/1.jpg";
import interior from "../../../assets/interior.jpeg";

const Section4 = () => {
  return (
    <div className="home3-section4-container">
      <h1>
        About <span>Us</span>
      </h1>
      <span>about finapsys</span>
      <img className="director-image" src={image} alt="director-image" />
      <div
        className="home3-section4-container-details"
        style={{
          backgroundImage: `url(${interior})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <span>Our Little Story</span>
        <h1>Something About Us</h1>
        <p>
          <span>
            A wonderful serenity has taken possession of my entire soul, like
            these sweet mornings of spring which I enjoy with my whole heart. I
            am alone, and feel the charm of existence in this spot, which was
            created for the bliss of souls like mine. I am so happy, my dear
            friend, so absorbed in the wind I
          </span>
          <span>
            should be incapable of drawing a single stroke at the present
            moment; and yet I feel that I never was a greater artist than now.
            When, while the lovely valley teems with vapour around me, and the
            meridian. I throw myself down among the tall grass by the trickling
            stream; and.
          </span>
        </p>
      </div>
    </div>
  );
};

export default Section4;
