import React, { useState } from "react";
import "./section7.scss";
import { IoIosAnalytics } from "react-icons/io";
import { SiMicrostrategy } from "react-icons/si";
import { GrResources } from "react-icons/gr";
import { IoCodeWorkingSharp } from "react-icons/io5";
import { IoMdWifi } from "react-icons/io";

const Section7 = () => {
  const info = [
    {
      icon: <IoIosAnalytics />,
      heading: "Holistic Analysis",
      info: [
        "Understanding clients' requirements",
        "Preliminary problem diagnosis",
        "Assignment planning",
        "Proposals to client",
        "Consulting contract",
      ],
    },
    {
      icon: <SiMicrostrategy />,
      heading: "Conceptualize Strategy",
      info: [
        "Aligning process and/ or, people skill with vision and business model of organization.",
      ],
    },
    {
      icon: <GrResources />,
      heading: "Resource Allocation & Implementation",
      info: [
        "Job wise resource allocation.",
        "Defining, driving & continuously measuring performance.",
      ],
    },
    {
      icon: <IoCodeWorkingSharp />,
      heading: "Execution Plan",
      info: ["Developing solutions, time-lines & accountabilities."],
    },
    {
      icon: <IoMdWifi />,
      heading: "Recalibration",
      info: [
        "Ensuring evolution of the program is in-line with the organization objectives.",
        "Deviation reports & rectification measures.",
        "Plans for follow-up.",
      ],
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="section7-container">
      <div className="section7-heading-intro">
        <span>our process</span>
        <h1>
          Our <span>Consultancy</span> Process
        </h1>
        <p>
          Our team at finapsys adhere to a process for all consultency work
          undertaken to ensure our client can expect a consistently high-
          quality service that meets their needs.
        </p>
      </div>

      <div className="section7-tab-container">
        <div className="tab-headings">
          {info.map((item, index) => (
            <div
              key={index}
              className={`tab-heading ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              <span className="tab-icon">{item.icon}</span>
              <span className="tab-text">{item.heading}</span>
            </div>
          ))}
        </div>

        <div className="tab-content">
          {info[activeTab].info.map((detail, index) => (
            <p key={index}>{detail}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section7;
