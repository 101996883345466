import React, { useState } from "react";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa"; 
import "./section4.scss";

const Section4 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="section4-container">
      <div className="section4-intro">
        <h1>
          Check Out Our Latest <span>Webinar</span>
        </h1>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage.
        </p>
        <button className="primary-button">Watch More</button>
      </div>

      <div className="play-icon" onClick={openModal}>
        <FaPlay />
      </div>

      <div className="section4-video">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=ovy-ypRjbvM"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>

      {isModalOpen && (
        <div className="video-modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=ovy-ypRjbvM"
              width="100%"
              height="100%"
              controls={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Section4;
