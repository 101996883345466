import React from "react";
import "./section9.scss";

const Section9 = () => {
  const info = [
    {
      value: "5000+",
      text: "Downloads",
    },
    {
      value: "3000+",
      text: "Feedback",
    },
    {
      value: "500+",
      text: "Workers",
    },
    {
      value: "70+",
      text: "Contributors",
    },
  ];

  return (
    <div className="section9-container">
      <div className="section9-heading-intro">
        <h1>We completed 2500+ Projects Yearly Successfully & counting</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus,
          accusamus fugit. Quibusdam accusantium aliquid possimus ex sapiente
          obcaecati, ea soluta adipisci provident.
        </p>
      </div>
      <div className="section9-grid-container">
        {info.map((item, index) => (
          <div className="valued-card" key={index}>
            <h1>{item.value}</h1>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
      <div className="section9-contact">
        <div className="section-left">
          <h3>Have any question about us?</h3>
          <p>Don't hesitate to contact us.</p>
        </div>
        <div className="section-right">
            <button className="primary-button">Contact Us</button>
        </div>
      </div>
    </div>
  );
};

export default Section9;
