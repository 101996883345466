import React, { useEffect, useState, useRef } from "react";
import "./section2.scss";

const CounterCard = ({ targetValue, text }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const currentElement = elementRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const duration = 2000;
      const increment = targetValue / (duration / 16.67);

      const counter = setInterval(() => {
        start += increment;
        if (start >= targetValue) {
          clearInterval(counter);
          setCount(targetValue);
        } else {
          setCount(Math.ceil(start));
        }
      }, 16.67);
    }
  }, [isVisible, targetValue]);

  return (
    <div className="grid-card" ref={elementRef}>
      <h1>{count}+</h1>
      <p>{text}</p>
    </div>
  );
};

const Section2 = () => {
  const info = [
    { value: 5000, text: "Downloads" },
    { value: 3000, text: "Feedback" },
    { value: 500, text: "Workers" },
    { value: 70, text: "Contributors" },
  ];

  return (
    <div className="home2-section2-container">
      <div className="home2-section2-grid-container">
        {info.map((item, index) => (
          <CounterCard key={index} targetValue={item.value} text={item.text} />
        ))}
      </div>
    </div>
  );
};

export default Section2;
