import React from "react";
import "./section2.scss";
import image1 from "../../../assets/building.png";
import image2 from "../../../assets/img1.webp";
import shape1 from "../../../assets/_shape.png";
import shape2 from "../../../assets/shape.png";

const Section2 = () => {
  return (
    <div className="section2-container">
      <div className="image-container">
        <img src={image1} alt="image1" className="image1" />
        <img src={image2} alt="image2" className="image2" />
        <img src={shape1} alt="shape1" className="shape1" />
        <img src={shape2} alt="shape2" className="shape2" />
      </div>
      <div className="about-container">
        <span>Affordable & Effective</span>
        <h1>
          business <span>solution</span>
        </h1>
        <p>
          Finapsys Consultancy Services provides crucial business services in
          finance and technology for startups, new and established businesses,
          with a strong focus on success in the global post-pandemic era. The
          dedicated team of our expert profe
        </p>
        <button className="primary-button">Discover More</button>
      </div>
    </div>
  );
};

export default Section2;
